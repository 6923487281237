<template>
  <div class="mobile-cc-events-title">
    <div class="cc-events-txt">
      <div>{{ title || 'CC EVENTS' }}</div>
    </div>
    <div class="events-map">
      <Advertisement
          :imgWidth="'100%'"
          :imgHeight="'auto'"
          :banners="eventBanners"
          :controls="true"
      />
    </div>
    <br />
    <div v-if="!noPastEvents" class="all-events-btn-cont">
      <div>
        <router-link to="/past-events">
          <button class="eventsArchive">Past Events <b><i class="fas fa-chevron-right"></i></b></button>
        </router-link>
      </div>
      <div v-if="!noSelectBox" class="selectBox">
        <b-form-select
          v-model="selectedTag"
          @change="filterEvents"
          size="sm"
          class="mt-3"
        >
          <option :value="''">All</option>
          <option v-for="{ name, id } in eventTags" :key="id" :value="id">
            {{ name }}
          </option>
        </b-form-select>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import Advertisement from '../advertisement/Advertisement.vue';

export default {
  name: 'EventSlideshow',
  components: {
    Advertisement,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    noSelectBox: {
      type: Boolean,
      default: false,
    },
    noPastEvents: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      eventBanners: [],
      eventTags: [],
      selectedTag: '',
    };
  },
  async mounted() {
    this.eventTags = await this.fetchEventsTags();
    await this.fetchAdvertisementBanners('event').then(res => (this.eventBanners = res));
  },
  methods: {
    ...mapActions({
      fetchEventsTags: 'events/fetchEventsTags',
      fetchAdvertisementBanners: 'advertisement/fetchAdvertisementBanners',
      fetchEvents: 'events/fetchAllEvents',
    }),
    filterEvents(tagId) {
      this.fetchEvents(tagId);
    },
  },
};
</script>

<style scoped>
.all-events-btn-cont .fa-chevron-right {
  display: none;
}

.selectOptionsBtn {
  font-size: 12px;
  outline: none;
  box-shadow: none;
  resize: none;
  width: 51%;
  background: #bd9d5f;
  border: navajowhite;
  color: white;
  margin: 9px 9px 2px 52px;
}
.all-events-btn-cont {
  display: flex;
  justify-content: space-between;
}
.selectBox > select {
    margin: 0 !important;
    background-color: #ebebeb;
    width: 54px;
    border: none;
}
 .cc-events-txt {
    border-radius: 0 10px 10px 10px;
    }
</style>
<style scoped>
@media (max-width:768px) {
  .mobile-cc-events-title {
    max-width:768px;
  }
  .mobile-cc-events-title br {
    display: none;
  }
  .cc-events-txt {
    border-radius: 0 10px 10px 10px;
    color: #666;
    margin-bottom: 2px;
    height: auto;
    padding: 16px;
    font-size: 13px;
  }
  .all-events-btn-cont {
    justify-content: flex-end;
    background-color: #fff;
    border-radius: 0 0 10px 10px;
    padding: 8px;
  }
  .all-events-btn-cont a .eventsArchive {
    border-radius: 10px 10px 0 10px;
    font-size: 11px;
    padding: 3px 6px;
    border: none;
    background-color: transparent;
    color: #bd9d5f;
  }
  .all-events-btn-cont .fa-chevron-right {
    display: inline-block;
  }
  .all-events-btn-cont .selectBox {
    display: none;
  }
}
</style>
